








import App,{Response,WebAdminUser} from "@/api/Api";
import { Component, Prop, Vue, Watch  } from 'vue-property-decorator';
@Component
export default class Input extends Vue{
  isLogin = false
  beforeMount(){
    const url:string = window.location.href
    if(url.includes('login')){
      this.isLogin = true
    }else{
      this.isLogin = false
    }
    App.web_user().then((res: Response<WebAdminUser>)=>{
      if(res.code === 401.3){
        App.route('/login')
      }
    }).catch(err=>{
      // App.route('/login')
    })
  }
}
