//    HTTP 400 - 请求无效
//    HTTP 401.1 - 未授权：登录失败
//    HTTP 401.2 - 未授权：服务器配置问题导致登录失败
//    HTTP 401.3 - ACL 禁止访问资源
//    HTTP 401.4 - 未授权：授权被筛选器拒绝
//    HTTP 401.5 - 未授权：ISAPI 或 CGI 授权失败
//    HTTP 403 - 禁止访问
//    HTTP 403 - 对 Internet 服务管理器 的访问仅限于 Localhost
//    HTTP 403.1 禁止访问：禁止可执行访问
//    HTTP 403.2 - 禁止访问：禁止读访问
//    HTTP 403.3 - 禁止访问：禁止写访问
//    HTTP 403.4 - 禁止访问：要求 SSL
//    HTTP 403.5 - 禁止访问：要求 SSL 128
//    HTTP 403.6 - 禁止访问：IP 地址被拒绝
//    HTTP 403.7 - 禁止访问：要求客户证书
//    HTTP 403.8 - 禁止访问：禁止站点访问
//    HTTP 403.9 - 禁止访问：连接的用户过多
//    HTTP 403.10 - 禁止访问：配置无效
//    HTTP 403.11 - 禁止访问：密码更改
//    HTTP 403.12 - 禁止访问：映射器拒绝访问
//    HTTP 403.13 - 禁止访问：客户证书已被吊销
//    HTTP 403.15 - 禁止访问：客户访问许可过多
//    HTTP 403.16 - 禁止访问：客户证书不可信或者无效
//    HTTP 403.17 - 禁止访问：客户证书已经到期或者尚未生效 HTTP 404.1 -
//    无法找到 Web 站点
//    HTTP 404- 无法找到文件
//    HTTP 405 - 资源被禁止
//    HTTP 406 - 无法接受
//    HTTP 407 - 要求代理身份验证
//    HTTP 410 - 永远不可用
//    HTTP 412 - 先决条件失败
//    HTTP 414 - 请求 - URI 太长
//    HTTP 500 - 内部服务器错误
//    HTTP 500.100 - 内部服务器错误 - ASP 错误
//    HTTP 500-11 服务器关闭
//    HTTP 500-12 应用程序重新启动
//    HTTP 500-13 - 服务器太忙
//    HTTP 500-14 - 应用程序无效
//    HTTP 500-15 - 不允许请求 global.asa
//    Error 501 - 未实现
//    HTTP 502 - 网关错误
import { Message } from 'element-ui';
import axios, { AxiosRequestConfig} from "axios";
import router from '../router'
export interface Response<T> {
    code: number,
    message: string,
    data: T
}
export interface WebAdminUser {
    WEBADMINUSERSESSIONID: string,
    webAdminUser: {
        nickname: string,
        id: string
    }
}
export interface ProjectAddress{
    id:number,
    projectId:string,
    address1:string,
    address2:string,
    address3:string,
    create_time:string
}
export interface Project{
    id:string,
    name:string,
    addressList:Array<ProjectAddress> | string
    createTime:string
}
export interface ProjectConfig{
    id:number,
    day:number,
    arriveTime:Array<string>
    arrive_offset_minute:Array<number>,
    closeTime:string,
    closeOffsetMinute:number,
    projectId:number,
    pause:boolean
}
export enum DayOfWeek{
    // 0-周日 1-周一 2-周二 3-周三 4-周四 5-周五 6-周六
    周日=0,
    周一=1,
    周二=2,
    周三=3,
    周四=4,
    周五=5,
    周六=6,
}
export interface Menu{
    id?:string,
    day:number,
    classId:string,
    projectId:string,
    create_time?:string
}
export interface StoreFoodConfig{
    id:number,
    foodId:number,
    classId:string,
    sort_index:number
}
export interface StoreOrder{
    id:number,
    orderNo:string,
    actuallyFee:string,
    companyName:string,
    receiveName:string,
    receivePhoneNumber:string,
    remark:string,
    address1:string,
    address2:string,
    address3:string,
    status:number,
    projectId:string,
    projectName:string,
    payTime:string,
    foods:Array<StoreOrderFood>
}
export interface StoreOrderFood{
    id:number,
    picUrl:string,
    orderId:number,
    foodId:number,
    foodName:string,
    num:number,
    arriveTime:string,
    dateTime:string,
    projectId:string,
    projectName:string,
    salePrice:number,
    actuallyFee:number,
    createTime:string
}
export interface Calendar{
    days:Array<Day>,
    menu:Array<FoodClass>
}
export interface Day{
    id:number,
    dayOfWeek:string,
    dayOfYear:string,
    date:string
}
export interface menu{

}
export interface Supplier{
    id:string,
    name:string,
    foodClass: Array<FoodClass>,
    createTime:string
}
export interface FoodClass{
    id:string,
    name:string,
    enable:number,
    projectId:Array<string>,
    supplierId:number,
    day:Array<number>,
    foods:Array<Food>
}
export interface Food{
    id:number,
    picUrl?:string,
    title:string,
    enable?:number,
    salePrice:number,
    classId:Array<string>,
    supplierId: string,
    createTime:string
}
const getBlob = function (url: string, cb: Function) {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
        if (xhr.status === 200) {
            cb(xhr.response);
        }
    };
    xhr.send();
}

const saveAs = function (blob: Blob) {

    const link = document.createElement("a");
    const body: HTMLElement | null = document.querySelector("body");

    link.href = window.URL.createObjectURL(blob);
    // link.download = filename;

    // fix Firefox
    link.style.display = "none";
    body?.appendChild(link);

    link.click();
    body?.removeChild(link);

    window.URL.revokeObjectURL(link.href);
}

const header: any = {}

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    const method: any = config.method
    const headers = config.headers[method]

    const storage = window.localStorage

    const keys = Object.keys(header)
    for (let i = 0; i < keys.length; i++) {
        if(headers[keys[i]]){
            headers[keys[i]] = headers[keys[i]]
        }
    }
    if(storage['WEBADMINUSERSESSIONID']){
        headers['WEBADMINUSERSESSIONID'] = storage['WEBADMINUSERSESSIONID']
    }
    return config
})

const get = (url: string, params?: object | null): Promise<any> => {
    console.log('请求接口:'+url + ",请求参数:"+JSON.stringify(params));
    return new Promise((resolve, reject) => {
        axios.get(url, { params }).then(response => resolve(response.data)).catch(err => reject(err))
    })
}

const post = (url: string, params: object | null): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post(url, params).then(response => resolve(response.data)).catch(err => {
            reject(err)
        })
    })
}

const _delete = (url:string): Promise<any> => {
    return new Promise((resolve,reject)=>{
        axios.delete(url).then(response => resolve(response.data)).catch(err => {
            reject(err)
        })
    })
}

const error = (message: string) => {
    Message.error(message)
}
export default class App{
    // Vue 路由跳转
    static route(path: string, params?: any): void {
        router.push({ path: path, query: params })
    }

    static error(message: string): void {
        Message.error(message)
    }
    static success(message: string): void{
        Message.success(message)
    }
    static info(message: string): void{
        Message.info(message)
    }
    // static download(url: string, params?: object | null){
    //     const query:any = {
    //         url,
    //         method:'POST',
    //         data:params
    //     }
    //     axios.request(query).then(res=>{
    //         const filename = "order.xlsx";
    //         const blob = new Blob([res.data]);
    //         const blobURL = window.URL.createObjectURL(blob)
    //         const aLink = document.createElement("a");
    //         aLink.style.display = 'none'
    //         aLink.href = blobURL
    //         aLink.setAttribute('download', decodeURI(filename))
    //         // 挂载a标签
    //         document.body.appendChild(aLink)
    //         aLink.click()
    //         document.body.removeChild(aLink)
    //         // 释放blob URL地址
    //         window.URL.revokeObjectURL(blobURL)
    //     })
    //     // axios.post(url, params).then(res=>{
    //     //     var name = "订单.xlsx";
    //     //     var blob = new Blob([res.data]);
    //     //     var url = window.URL.createObjectURL(blob);
    //     //     var aLink = document.createElement("a");
    //     //     aLink.style.display = "none";
    //     //     aLink.href = url;
    //     //     aLink.setAttribute("download", name);
    //     //     document.body.appendChild(aLink);
    //     //     aLink.click();
    //     //     document.body.removeChild(aLink); //下载完成移除元素
    //     //     window.URL.revokeObjectURL(url); //释放掉blob对象
    //     //     //  saveAs(new Blob([res.data]))
    //     // })
    // }

    static download(url: string, params?: object | null) {

        axios.post(url, params, {
            responseType: 'blob', headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                // Accept:'application/octet-stream',
                // 'filename':'utf-8'
            }
        }).then(res => {
            var name = "订单.xlsx";
            var blob = new Blob([res.data]);
            var url = window.URL.createObjectURL(blob);
            var aLink = document.createElement("a");
            aLink.style.display = "none";
            aLink.href = url;
            aLink.setAttribute("download", name);
            document.body.appendChild(aLink);
            aLink.click();
            document.body.removeChild(aLink); //下载完成移除元素
            window.URL.revokeObjectURL(url); //释放掉blob对象
            //  saveAs(new Blob([res.data]))
        })
    }

    static headers(): any {
        const headers = header
        const storage = window.localStorage
        const keys = Object.keys(header)
        for (let i = 0; i < keys.length; i++) {
            headers[keys[i]] = headers[keys[i]]
        }
        headers['WEBADMINUSERSESSIONID'] = storage['WEBADMINUSERSESSIONID']
        return header
    }

    static putHeader(headerName: string, headerValue: string) {
        header[headerName] = headerValue
        localStorage[headerName] = headerValue
    }
    static removeHeader(headerName:string){
        header[headerName] = null
        localStorage[headerName] = null
    }



    static getHeader(headerName: string):string{
        return App.headers()['WEBADMINUSERSESSIONID']
    }


    static get(url: string, params: object | null): Promise<Record<string, any>> {
        return get(url, params)
    }

    static post(url: string, params: object | null): Promise<Record<string, any>> {
        // return post(url, params)
        return new Promise((resolve,reject)=>{
            post(url, params).then(res =>{
                resolve(res)
                if(res.code === 400){
                    error(res.message)
                }
            }).catch(err => {
                reject(err)
                const _error = err.toString()
                if (_error.includes("404")){
                    error('服务器资源不可用')
                }else{
                    error('服务器异常')
                }
            })
        })
    }


    static web_user(): Promise<Response<WebAdminUser>>{
        return get('/mt/authenticated/web_admin_user')
    }
    static web_user_login(nickname: string, pwd: string): Promise<Response<WebAdminUser>>{
        return post('/mt/web_admin_user_login',{nickname,pwd})
    }





    /**
     * 项目-列表
     * @returns 
     */
    static project(): Promise<Response<Array<Project>>>{
        return get('/mt/authenticated/project')
    }
    /**
     * 保存项目
     * @param project 
     * @returns 
     */
    static project_new(project:Project):Promise<Response<null>>{
        return post('/mt/authenticated/project',project)
    }
    /**
     * 编辑项目
     * @param project 
     * @returns 
     */
    static project_update(project:Project): Promise<Response<null>>{
        return post('/mt/authenticated/project/'+project.id,project)
    }
    /**
     * 删除项目
     * @param id 
     * @returns 
     */
    static project_delete(id:string): Promise<Response<null>>{
        return _delete('/mt/authenticated/project/'+id)
    }





    /**
     * 项目地址
     * @param projectId 
     * @returns 
     */
    static project_address(projectId:string):Promise<Response<any>>{
        return get('/mt/authenticated/project/address',{projectId})
    }

    /**
     * 修改项目营业执照
     * @param businessLicense 
     * @returns 
     */
    static project_license_get(): Promise<Response<string>> {
        return get('/mt/business_license', null)
    }
    /**
     * 修改项目营业执照
     * @param businessLicense 
     * @returns 
     */
    static project_license(businessLicense: string): Promise<Response<any>>{
        return post('/mt/business_license', { businessLicense})
    }


    /**
     * 项目配置
     * @param projectId 
     * @param dayOfWeekValue 
     * @returns 
     */
    static project_config(projectId:string,dayOfWeekValue:number): Promise<Response<ProjectConfig>>{
        return get('/mt/authenticated/project/config',{projectId,dayOfWeekValue})
    }
    static project_config_update(projectConfig:ProjectConfig):Promise<Response<null>>{
        return post("/mt/authenticated/project/config/"+projectConfig.id,projectConfig)
    }



    static calendar(projectId: string,day?: DayOfWeek | null ): Promise<Response<Calendar>>{
        return get('/mt/authenticated/menu', { projectId,dayOfWeekValue:day})
    }

    /**
     * 供应商-列表
     * @returns 
     */
    static supplier(): Promise<Response<Array<Supplier>>>{
        return get('/mt/authenticated/supplier')
    }



    /**
     * 新增菜单
     * @param menu 
     * @returns 
     */
    static menu(menu:Menu): Promise<Response<Menu>>{
        return post('/mt/authenticated/menu',menu)
    }
    /**
     * 删除鼒
     * @param id 
     * @returns 
     */
    static menu_del(id:string,projectId:string,day:number):Promise<Response<null>>{
        return _delete('/mt/authenticated/menu/'+id+'?day='+day + '&projectId='+projectId)
    }


    /**
     * 菜品-新增
     * @param food 
     * @returns 
     */
    static supplier_food(food: Food): Promise<Response<Food>>{
        return post('/mt/authenticated/supplier/food',food)
    }

    /**
     * 菜品-编辑
     * @param food 
     * @returns 
     */
    static supplier_food_id(food: Food): Promise<Response<Food>>{
        return post('/mt/authenticated/supplier/food/' + food.id, food)
    }

    /**
     * 菜品-删除
     * @param id 
     * @returns 
     */
    static supplier_food_id_del(id: number): Promise<Response<null>> {
        return _delete('/mt/authenticated/supplier/food/' + id)
    }

    /**
     * 批量保存菜品
     * @param foods 
     * @returns 
     */
    static supplier_food_batch_id(classId:string,foods: Array<Food>): Promise<Response<null>>{
        return post('/mt/authenticated/supplier/batch_food?classId=' + classId,foods)
    }
    /**
     * 
     * @param id 
     * @returns 
     */
    static supplier_food_class_id_del(id:string): Promise<Response<null>>{
        return _delete('/mt/authenticated/supplier/food_class/'+id)
    }



    static order(projectId:string | null,
        status:Array<string> | null , 
        keyword : string | null,
        dateRange?:Array<string>): Promise<Response<Array<StoreOrder>>>{
        return post("/mt/authenticated/order2", { projectId, status, keyword, dateRange})
    }

    static order_refund(orderId:number): Promise<Response<any>>{
        return get('/mt/authenticated/order/refund',{id:orderId})
    }

    static store_food_config(classId: string): Promise<Response<Array<StoreFoodConfig>>>{
        return get('/mt/authenticated/supplier/food_config', { classId})
    }
    
}